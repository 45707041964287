/* CSS HEX 
--light-purple: #8e7eff;
--purple: #624bff;
--dark-purple: #4e33ff;
--light-blue: #3385ff;
--blue: #0066ff;
--dark-blue: #0066ff;
--light-black: #001c47;
--black: #000814;
--dark-black: #000000;

--light-yellow: #ffd452;
--yellow: #ffc71f;
--dark-yellow: #ebb000;
--light-red: #ff6665;
--red: #ff3432;
--dark-red: #e22e2c;
--light-green: #00cc5f;
--green: #00b856;
--dark-green: #00853e;
--orange-light: #ffa929;
--orange: #f59300;
--orange-dark: #d98200;

--light-white: #EEEEEE;
--white: #fcfcfc;
--dark-white: #ffffff;
*/

:root, html[class=""] {
    --background-color: #fcfcfc;
    --header-background-color: #0066ff;
    --subheader-background-color: #EAEAEA;
    --formcontrol-input-bg-color: #fcfcfc;
    --sidebar-background-color: #EAEAEA;
    --header-link-color: #EAEAEA;
    --header-link-active-color: #fcfcfc;
    --header-dropdown-background-color: #fcfcfc;
    --header-dropdown-background-color-hover: #EAEAEA;
    --header-search-text: #242424;
    --header-search-background-color: #EAEAEA;
    --header-search-background-color-active: #fcfcfc;
    --sidebar-tab: #050505;
    --sidebar-active-tab: #D8D8D8;
    --link-color: #242424;
    --link-active-color: #050505;
    --link-active-tab-color: #ffffff;
    --link-active-background-color: #00000052;
    --sidebar-link-active-hover-color: #0066ff;
    --datatable-title-color: #242424;
    --datatable-active-pagination-color: #0066ff;
    --datatable-pagination-color: #EAEAEA;
    --title-color: #050505;
    --content-color: #242424;
    --border-active-hover-color: #24242452;
    --badge-content-color: #0066ff;
    --badge-success-color: #00cc5f;
    --badge-danger-color: #ff6665;
    --badge-warning-color: #f59300;
    --badge-info-color: #3385ff;
    --primary-border-color: #D8D8D8;
    --border-color: #D8D8D8;
    --popup-tabs-color: #eaeaea;
    --popup-active-tab-color: #0066ff;
    --secondary-border-color: #d8d8d8;
    --card-background-color: #FCFCFC;
    --dropdown-background-color: #FCFCFC;
    --table-hover: #EAEAEA;
    --button-success-color: #00853e;
    --button-success-hover-color: #217645;
    --button-primary-color: #0066ff;
    --button-primary-hover-color: #0052cc;
    --button-danger-color: #e22e2c;
    --button-danger-hover-color: #dc3545;
    --success-alert-content-color: #00853e;
    --success-alert-background-color: #eaeaea;
    --success-alert-border-color: #d8d8d8;
    --success-alert-close-button: #00853e;
    --fail-alert-content-color: #e22e2c;
    --fail-alert-background-color: #eaeaea;
    --fail-alert-border-color: #d8d8d8;
    --fail-alert-close-button: #e22e2c;
    --svg-danger-color: #e22e2c;
    --button-text-color: #EAEAEA;
    --button-text-hover-color: #ffffff;
    --login-screen-card-background: #eaeaea;
    --login-screen-card-border: #eaeaea;
    --login-screen-pin-box-background: #fcfcfc;
    --login-screen-pin-box-border: #d8d8d8;
    --login-password-switch-color-active: #eaeaea;
    --login-password-switch-color: #0066ff;
    --dropdown-background-hover-color: #d8d8d8;
    --login-switch-slider-background-color: #fcfcfc;
    --login-switch-slider-background-color-active: #0066ff;
    --pin-switch: #eaeaea;
    --progress-loader-color: #d8d8d8;
    --sidebar-badge-content: #fcfcfc;
    --branch-tag: #eaeaea;
    --pagination-link-color: #D8D8D8;
    --copy-btn-color: #fcfcfc;
    --icon-fill-color: #fcfcfc;
    --icon-fill-color-dark: #fcfcfc;
    --radio-btn-bg: #fcfcfc;
    --tooltip-bg-color: #050505;
    --tooltip-content-color: #d8d8d8;
    --placeholder-content-color: #00000052;
    --loader-background-color: #EAEAEA;
    --loader-foreground-color: #fcfcfc;
    --dropdown-shadow: #eaeaea;
    --selected-option: #24242452;
    --focus-border-ring: #99c2ff;
}

html[class="dark"] {
    --background-color: #050505;
    --header-background-color: #121212;
    --subheader-background-color: #242424;
    --formcontrol-input-bg-color: #242424;
    --sidebar-background-color: #121212;
    --grid-list-bg-color: #121212;
    --header-link-color: #D8D8D8;
    --header-link-active-color: #fcfcfc;
    --header-dropdown-background-color: #121212;
    --header-dropdown-background-color-hover: #242424;
    --header-search-text: #D8D8D8;
    --header-search-background-color: #121212;
    --table-row-bg-color: #121212;
    --header-search-background-color-active: #242424;
    --sidebar-tab: #fcfcfc;
    --sidebar-active-tab: #242424;
    --link-color: #D8D8D8;
    --link-active-color: #ffffff;
    --link-active-tab-color: #ffffff;
    --link-active-background-color: #242424;
    --sidebar-link-active-hover-color: #0066ff;
    --datatable-title-color: #D8D8D8;
    --datatable-active-pagination-color: #0066ff;
    --datatable-pagination-color: #242424;
    --title-color: #D8D8D8;
    --content-color: #d8d8d8;
    --border-active-hover-color: #D8D8D852;
    --popup-tabs-color: #242424;
    --popup-active-tab-color: #0066ff;
    --badge-content-color: #0066ff;
    --badge-warning-color: #f59300;
    --badge-success-color: #00cc5f;
    --badge-danger-color: #ff6665;
    --badge-info-color: #3385ff;
    --primary-border-color: #242424;
    --border-color: #363636;
    --secondary-border-color: #242424;
    --card-background-color: #121212;
    --dropdown-background-color: #121212;
    --dropdown-background-hover-color: #242424;
    --table-hover: #242424;
    --button-success-color: #00853e;
    --button-success-hover-color: #217645;
    --button-primary-color: #0066ff;
    --button-primary-hover-color: #0052cc;
    --button-danger-color: #e22e2c;
    --button-danger-hover-color: #dc3545;
    --noresult-background-color: #242424;
    --success-alert-content-color: #00853e;
    --success-alert-background-color: #242424;
    --success-alert-border-color: #363636;
    --success-alert-close-button: #00853e;
    --fail-alert-content-color: #e22e2c;
    --fail-alert-background-color: #242424;
    --fail-alert-border-color: #121212;
    --fail-alert-close-button: #e22e2c;
    --svg-danger-color: #e22e2c;
    --button-text-color: #D8D8D8;
    --button-text-hover-color: #ffffff;
    --login-screen-card-background: #121212;
    --login-screen-card-border: #121212;
    --login-screen-pin-box-background: #242424;
    --login-screen-pin-box-border: #363636;
    --login-password-switch-color: #0066ff;
    --login-password-switch-color-active: #d8d8d8;
    --login-switch-slider-background-color: #242424;
    --login-switch-slider-background-color-active: #0066ff;
    --pin-switch: #242424;
    --progress-loader-color: #0066ff;
    --sidebar-badge-content: #fcfcfc;
    --branch-tag: #363636;
    --pagination-link-color: #D8D8D8;
    --copy-btn-color: #121212;
    --icon-fill-color: #121212;
    --icon-fill-color-dark: #050505;
    --radio-btn-bg: #242424;
    --tooltip-bg-color: #d8d8d8;
    --tooltip-content-color: #242424;
    --placeholder-content-color: #d8d8d852;
    --loader-background-color: #242424;
    --loader-foreground-color: #050505;
    --dropdown-shadow: #25252552;
    --selected-option: #d8d8d852;
    --focus-border-ring: #99c2ff;
}